import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  CollectionMethod,
  DurationUnit,
  Subscription,
  SubscriptionStatus,
} from '@wix/ambassador-billing-v1-subscription/types';
import { LineItem, LineObject } from '../Line/Line';
import { isInGracePeriod, isRecurringSubscription } from '../../domainUtils';
import { formatDate, formatMoney, getPaymentMethodDisplayName } from '../../../../utils/displayUtils';
import { classes } from './SubscriptionsListItemBass.st.css';
import { Text } from 'wix-ui-tpa';
import { RootState } from '../../state';
import { getDetails, getRegionalSettings } from '../../selectors';
import { connect } from '../../../../common/components/runtime-context';

type Props = {
  subscription: Subscription;
};
type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

const FrequencyTranslations = {
  [DurationUnit.DAY]: 'app.details.price-per-day',
  [DurationUnit.WEEK]: 'app.details.price-per-week',
  [DurationUnit.MONTH]: 'app.details.price-per-month',
  [DurationUnit.YEAR]: 'app.details.price-per-year',
  [DurationUnit.UNKNOWN]: 'app.details.price-undefined',
};

const SubscriptionPaymentsDetails = ({ subscription, regionalSetting, details }: Props & RuntimeProps) => {
  const { t } = useTranslation();
  const endDate = subscription.endDate;
  const hasEndDate = Boolean(endDate);

  const getLastChargeTooltipText = () => {
    let lastChargeTooltipText;

    if (details?.shouldShowLastNextCharge) {
      const lastChargeAmount = formatMoney(
        Number(subscription.billingStatus!.latestPaymentData?.totals?.totalPrice),
        subscription.billingSettings!.currency!,
        regionalSetting,
      );
      const discountAmount = subscription?.billingStatus?.latestPaymentData?.totals?.discount;

      const formattedDiscount =
        discountAmount && formatMoney(Number(discountAmount), subscription.billingSettings!.currency!, regionalSetting);

      lastChargeTooltipText = (
        <div className={classes.tooltipContent}>
          <Text>{t('app.details.payment-price', { amount: lastChargeAmount })}</Text>
          {formattedDiscount && <Text>{t('app.details.payment-discount', { discount: formattedDiscount })}</Text>}
        </div>
      );
    }
    return lastChargeTooltipText;
  };

  const getNextChargeTooltipText = () => {
    let nextChargeTooltipText;
    if (details?.shouldShowLastNextCharge && details?.nextCharge) {
      const nextChargeAmount = formatMoney(
        Number(details?.nextCharge?.totals?.totalPrice),
        subscription.billingSettings!.currency!,
        regionalSetting,
      );
      const discountAmount = details?.nextCharge?.totals?.discount;

      const formattedDiscount =
        discountAmount && formatMoney(Number(discountAmount), subscription.billingSettings!.currency!, regionalSetting);

      nextChargeTooltipText = (
        <div className={classes.tooltipContent}>
          <Text>{t('app.details.payment-price', { amount: nextChargeAmount })}</Text>
          {formattedDiscount && <Text>{t('app.details.payment-discount', { discount: formattedDiscount })}</Text>}
        </div>
      );
    }
    return nextChargeTooltipText;
  };
  const getPrice = (subscription: Subscription): number =>
    subscription.items?.reduce(
      (result, item) => result + parseFloat(item?.pricingModel?.fixedPrice?.itemPrice || '0'),
      0,
    ) || 0;

  const getSubscriptionPaymentsDetails = () => {
    const lines: LineObject[] = [];

    const price = getPrice(subscription);
    if (price && price > 0) {
      const currency = subscription.billingSettings?.currency;
      const cycleDuration = subscription.billingSettings?.cycleDuration;
      if (price && currency) {
        lines.push({
          text:
            isRecurringSubscription(subscription) && cycleDuration
              ? t(FrequencyTranslations[subscription.billingSettings?.cycleDuration?.unit!], {
                  price: formatMoney(price, currency, regionalSetting),
                  count: cycleDuration?.count ?? 1,
                })
              : formatMoney(price, currency, regionalSetting),
        });
      }
    } else {
      lines.push({ text: t('app.details.free-plan') });
    }
    if (subscription.billingSettings?.collectionMethod === CollectionMethod.AUTO_CHARGE) {
      if (subscription.billingStatus) {
        const billingStatus = subscription.billingStatus;
        const totalSuccessfulPayments =
          subscription.status === SubscriptionStatus.PENDING && billingStatus.currentCycle === 0
            ? subscription.billingSettings.customBillingSchedule?.cyclesToPayOnCreation || 0
            : billingStatus.currentCycle;

        if (hasEndDate && subscription.billingSettings.totalCycles) {
          lines.push({
            text: t('app.details.payments-completed-of-total', {
              totalBillingCycles: subscription.billingSettings.totalCycles,
              totalSuccessfulPayments,
            }),
          });
        } else {
          lines.push({ text: t('app.details.payments-completed', { totalSuccessfulPayments }) });
        }
        if (billingStatus.previousBillingDate) {
          const lastChargeTooltipText = getLastChargeTooltipText();
          lines.push({
            text: t('app.details.last-payment', {
              date: formatDate(billingStatus.previousBillingDate, regionalSetting),
            }),
            tooltipText: lastChargeTooltipText,
            dataHook: 'last-payment',
          });
        }
        if (billingStatus.nextBillingDate) {
          const nextPaymentDataHook = 'next-payment';
          if (isInGracePeriod(subscription)) {
            lines.push({
              text: t('app.details.next-payment', {
                date: t('app.settings.details.payment-date-na'),
                interpolation: { escapeValue: false },
              }),
              dataHook: nextPaymentDataHook,
            });
          } else {
            const nextChargeTooltipText = getNextChargeTooltipText();
            lines.push({
              text: t('app.details.next-payment', {
                date: formatDate(billingStatus.nextBillingDate, regionalSetting),
                interpolation: { escapeValue: false },
              }),
              tooltipText: nextChargeTooltipText,
              dataHook: nextPaymentDataHook,
            });
          }
        }
        if (details.paymentMethodDetails?.savedCreditCardDetails) {
          lines.push({
            text: t('app.details.payment-method.info', {
              paymentMethod: getPaymentMethodDisplayName(
                details.paymentMethodDetails.savedCreditCardDetails?.network,
                t,
              ),
              cardNumber: details.paymentMethodDetails.savedCreditCardDetails?.lastFourDigits,
            }),
            dataHook: 'payment-method-details',
          });
        }
        if (details.paymentMethodDetails?.payPalDetails) {
          lines.push({
            text: t('app.details.payment-method.info.pay-pal'),
            dataHook: 'payment-method-details',
          });
        }
      }
    }
    if (subscription.billingSettings?.collectionMethod === CollectionMethod.OFFLINE) {
      lines.push({ text: t('app.details.payment-method-offline') });
    }
    return lines;
  };
  const paymentDetails = getSubscriptionPaymentsDetails();

  return (
    <>
      {paymentDetails.map((line, i) => (
        <div key={i}>
          <LineItem line={line} />
        </div>
      ))}
    </>
  );
};

const mapRuntimeToProps = (state: RootState, { subscription }: Props) => {
  const subscriptionId: string = subscription.id!;
  return {
    details: getDetails(state, subscriptionId),
    regionalSetting: getRegionalSettings(state),
  };
};

export default connect(mapRuntimeToProps)(SubscriptionPaymentsDetails);
