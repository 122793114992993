import React, { useState } from 'react';
import { Button, ButtonPriority, InputDialog, ThreeDotsLoader, Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa';
import {
  useBi,
  useEnvironment,
  useErrorMonitor,
  useExperiments,
  useFedopsLogger,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { connect } from '../../../../common/components/runtime-context';
import {
  getDetails,
  getLanguage,
  getPayNowModalSubscription,
  getRegionalSettings,
  isPayNowModalOpen,
} from '../../selectors';
import { RootState } from '../../state';
import { PaymentsWidget, PaymentsWidgetAPI } from '@wix/cashier-payments-widget/lazy';
import { IActionCreators } from '../../../../types/internal-types';
import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { classes, st } from './PayNowModal.st.css';
import classNames from 'classnames';
import { Interactions } from '../../../../types/interactions';
import { PageType } from '@wix/cashier-common/dist/src/enums/PageType';
import { PayNowFlowStatus } from '../../constants';
import {
  aClickCancelPayNowEvent,
  aClickSubmitPayNowEvent,
  aPayNowSubmitResponseEvent,
} from '@wix/bi-logger-my-subscriptions/v2';
import { formatMoney } from '../../../../utils/displayUtils';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;
interface Props {
  subscription: Subscription;
}
export const PayNowModal = ({
  isOpen,
  subscription,
  actionCreators,
  language,
  IdentityParams,
  paymentOrderId,
  payNowFlowStatus,
  regionalSetting,
}: RuntimeProps & Props) => {
  const { isMobile } = useEnvironment();
  const biLogger = useBi();
  const [shouldDisableSubmitBtn, setShouldDisableSubmitBtn] = useState(false);
  const [isWidgetFullyMounted, setIsWidgetFullyMounted] = useState(false);
  const paymentsWidgetApi = React.useRef<PaymentsWidgetAPI | undefined>();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { msid, instance, siteOwnerId, sessionId, visitorId } = IdentityParams;
  const fedopsLogger = useFedopsLogger();
  const errorMonitor = useErrorMonitor();
  const endInteraction = () => {
    fedopsLogger.interactionEnded(Interactions.SubscriptionPayNow);
    actionCreators.setPayNowFlowStatus(PayNowFlowStatus.INIT);
  };

  const PayNowModalSubmit = async () => {
    biLogger.report(aClickSubmitPayNowEvent({ subscriptionId: subscription.id!, paymentOrderId }));
    fedopsLogger.interactionStarted(Interactions.SubscriptionPayNow);
    if (payNowFlowStatus === PayNowFlowStatus.INIT) {
      actionCreators.setPayNowFlowStatus(PayNowFlowStatus.PENDING);
      if (!paymentsWidgetApi.current?.pay) {
        errorMonitor.captureMessage(`Payments Widget did not provide pay function, subscription: ${subscription.id}`);
        return;
      }
      try {
        const result = await paymentsWidgetApi.current?.pay!();
        biLogger.report(
          aPayNowSubmitResponseEvent({ subscriptionId: subscription.id!, response: result.clientStatus }),
        );
        if (result.clientStatus === 'Approved') {
          actionCreators.onSuccessfulPayNow({ subscriptionId: subscription.id! });
        } else if (result.clientStatus === 'Pending') {
          actionCreators.onPaymentPendingPayNow();
        } else if (result.clientStatus === 'Failed') {
          // payments widget will display the error
          endInteraction();
        }
        // unknown and in process can't really be returned
      } catch (e) {
        // payments widget will display the error
        endInteraction();
      }
    } else {
      endInteraction();
    }
  };

  const onClickCloseModal = (referral: string) => {
    biLogger.report(aClickCancelPayNowEvent({ referral, subscriptionId: subscription.id! }));
    if (payNowFlowStatus !== PayNowFlowStatus.PENDING) {
      actionCreators.closePayNowModal();
    }
  };

  const getModalSubtitleText = () => {
    if (subscription.billingStatus?.latestPaymentData?.totals?.totalPrice && subscription.billingSettings?.currency) {
      return t('app.checkout-form.pay-now-upm.text', {
        subscriptionName: subscription.name,
        amount: formatMoney(
          subscription.billingStatus?.latestPaymentData?.totals?.totalPrice,
          subscription.billingSettings?.currency,
          regionalSetting,
        ),
      });
    }
  };

  const getTotalAmoutText = () => {
    if (subscription.billingStatus?.latestPaymentData?.totals?.totalPrice && subscription.billingSettings?.currency) {
      return t('app.pay-now-upm.total', {
        amount: formatMoney(
          subscription.billingStatus?.latestPaymentData?.totals?.totalPrice,
          subscription.billingSettings?.currency,
          regionalSetting,
        ),
      });
    }
  };

  return (
    <div className={classNames(st(classes.root, { desktop: !isMobile, mobile: isMobile }))}>
      <InputDialog
        title={t('app.checkout-form.pay-now-upm.title')}
        // @ts-expect-error
        subtitle={
          <div className={classes.modalSubtitle} data-hook="pay-now-modal-subtitle">
            {getModalSubtitleText()}
          </div>
        }
        data-hook="pay-now-modal"
        isOpen={isOpen}
        onClose={() => {
          onClickCloseModal('exit_window');
        }}
        fullscreen={isMobile}
        customFooter={
          <div className={classes.modalFooter}>
            <Text data-hook="pay-now-modal-total-amount">{getTotalAmoutText()}</Text>
            <div className={classes.modalButtons}>
              <Button
                onClick={PayNowModalSubmit}
                fullWidth={isMobile}
                data-hook="pay-now-submit-button"
                upgrade
                disabled={shouldDisableSubmitBtn || !isWidgetFullyMounted}
              >
                {payNowFlowStatus === PayNowFlowStatus.PENDING ? (
                  <ThreeDotsLoader className={classes.threeDotsLoader} />
                ) : (
                  t('app.checkout-form.pay-now.submit-payment.button')
                )}
              </Button>
              {!isMobile && (
                <Button
                  onClick={() => onClickCloseModal('cancel_button')}
                  data-hook="pay-now-cancel-button"
                  priority={ButtonPriority.basicSecondary}
                  disabled={payNowFlowStatus === PayNowFlowStatus.PENDING}
                  upgrade
                >
                  {t('app.checkout-form.pay-now-upm.cancel.button')}
                </Button>
              )}
            </div>
          </div>
        }
      >
        <div
          className={classNames(classes.paymentsWidgetContainer, {
            [classes.pendingResponse]: payNowFlowStatus === PayNowFlowStatus.PENDING,
          })}
          data-hook="pay-now-payments-widget-container"
        >
          <PaymentsWidget
            configuration={{
              locale: language,
              appId: IdentityParams.appDefinitionId,
              appInstanceId: IdentityParams.appInstanceId,
              appInstance: instance,
              amount: '5',
              currency: subscription.billingSettings!.currency!,
              msid: msid || '',
              siteOwnerId,
              visitorId,
              viewMode: 'Site',
              isSignedInUser: true,
              appSessionId: sessionId,
              snapshotId: paymentOrderId,
            }}
            externalSubmitButton // ability to show our own button
            onCrash={() => {
              setShouldDisableSubmitBtn(true);
              errorMonitor.captureMessage(`Payments Widget Crashed On Pay Now for subscripiton id ${subscription.id}`);
            }} // Payments will show error message,
            onApiInit={(api) => {
              paymentsWidgetApi.current = api;
            }}
            onFullLoad={() => {
              setIsWidgetFullyMounted(true);
            }}
            isSaveCCEnabled={true}
            pageType={PageType.PaymentComponent}
          />
          <div className={classes.afterWidgetPadding} />
        </div>
      </InputDialog>
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, _: {}, actionCreators: IActionCreators) => {
  return {
    isOpen: isPayNowModalOpen(state),
    subscription: getPayNowModalSubscription(state),
    actionCreators,
    language: getLanguage(state),
    IdentityParams: state.IdentityParams,
    subscriptionExtraDetails: getDetails(state, state.payNowModal.subscriptionId!),
    paymentOrderId: state.payNowModal.paymentOrderId,
    payNowFlowStatus: state.payNowModal.payNowFlowStatus,
    regionalSetting: getRegionalSettings(state),
  };
};

export default connect(mapRuntimeToProps)(PayNowModal);
